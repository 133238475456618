// ** React Imports
import { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { useAvatar } from '@hooks/useAvatar'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power, LogOut } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

const UserDropdown = () => {
  const { t } = useTranslation()

  const { avatar } = useAvatar()
  
  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) setUserData(JSON.parse(localStorage.getItem('userData')))
  }, [])

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{(userData && (`${userData['firstname']}`)) || 'John Doe'}</span>
          <span className='user-status'>{(userData && userData.role ? `${t(userData.role)}` : '') || 'Admin'}</span>
        </div>
        {avatar}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/perfil' className={window.location.pathname.indexOf('perfil') !== -1 ? 'active' : ''}>
          <User size={14} className='me-75' />
          <span className='align-middle'>{t('Profile')}</span>
        </DropdownItem>
        { userData && userData.accessTokenAdmin ? (
          <Fragment>
            <DropdownItem tag={Link} to={`/trocar-usuario`}>
              <LogOut size={14} className='me-75' />
              <span className='align-middle'>{t('Return')}</span>
            </DropdownItem>
            <DropdownItem divider />
          </Fragment>
        ) : ('')}
        <DropdownItem tag={Link} to='/login' onClick={() => dispatch(handleLogout())}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>{t('Logout')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
