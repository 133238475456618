// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Orders Paginate
export const getOrdersPaginate = createAsyncThunk('orders/getOrdersPaginate', async (params) => {
  const response = await api
  .get(`orders/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Order
export const getOrder = createAsyncThunk('orders/getOrder', async (id) => {
  const response = await api
  .get(`order/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getOrderByQuoteId = createAsyncThunk('orders/getOrderByQuoteId', async (id) => {
  const response = await api
  .get(`order/quote/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getOrdersInputList = createAsyncThunk('orders/getOrdersInputList', async (params) => {
  const response = await api
  .get(`orders/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Order
export const addOrder = createAsyncThunk('orders/addOrder', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('order', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Order
export const updateOrder = createAsyncThunk('orders/updateOrder', async (data) => {
  const response = await api
  .put(`order/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Order
export const updateStatusOrder = createAsyncThunk('orders/updateStatusOrder', async (id) => {
  const response = await api
  .put(`order/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Send Invoice Order
export const sendInvoiceOrder = createAsyncThunk('orders/sendInvoiceOrder', async (data) => {
  const response = await api
  .put(`order/invoice/${data.id}`, data.data)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Order
export const deleteOrder = createAsyncThunk('orders/deleteOrder', async (id) => {
  const response = await api
  .delete(`order/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Incoming Order
export const updateIncomingOrder = createAsyncThunk('orders/updateIncomingOrder', async (id) => {
  const response = await api
  .post(`order/incoming/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Outgoind Order
export const updateOutgoingOrder = createAsyncThunk('orders/updateOutgoingOrder', async (id) => {
  const response = await api
  .post(`order/outgoing/${id}`)
  .catch(err => console.log(err))

  return response.data
})


export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    quote: null,
    inputList: null,
    invoice: null,
    incoming: null,
    outdoing: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
      state.quote     = payload.quote ? state.quote : null
    },
    handleRemoveInvoice: (state, action) => {
      const payload = action.payload
      state.invoice = payload.invoice ? state.invoice : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getOrdersPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.incoming  = null
        state.outdoing  = null
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getOrderByQuoteId.fulfilled, (state, action) => {
        state.quote  = action.payload
      })
      .addCase(getOrdersInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addOrder.fulfilled, (state, action) => {
        state.added           = action.payload
        state.selected        = action.payload.data
        state.updated         = null
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.updated     = action.payload
        state.added       = null
      })
      .addCase(sendInvoiceOrder.fulfilled, (state, action) => {
        state.invoice = action.payload
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.selected    = null
        state.deleted     = action.payload
      })
      .addCase(updateIncomingOrder.fulfilled, (state, action) => {
        state.incoming  = action.payload
      })
      .addCase(updateOutgoingOrder.fulfilled, (state, action) => {
        state.outdoing  = action.payload
      })
  }
})

export const { handleRemove, handleRemoveInvoice, removePostCodeSearch } = ordersSlice.actions

export default ordersSlice.reducer