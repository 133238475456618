import { DefaultRoute } from '../router/routes'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatNewDate = (date, type = 1, language = 'pt-BR') => new Intl.DateTimeFormat(language, type === 1 ? { hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric', year: 'numeric', hour12: false } : { month: 'short', day: 'numeric', year: 'numeric' }).format(new Date(date))

export const checkDateMin = (date, type = 1) => {
  const format = { hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric', year: 'numeric', hour12: false }
  const dateNew = new Intl.DateTimeFormat("fr-ca", format).format(new Date(date)).replace(/\D/g, "")
  const dateNow = new Intl.DateTimeFormat("fr-ca", format).format(new Date()).replace(/\D/g, "")
  
  return type === 1 ? dateNew <= dateNow : dateNew >= dateNow
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const updateUserData = (userData) => localStorage.setItem("userData", JSON.stringify(userData))

// ** Check Ability Admin
export const abilityAdminCheck = (array = null, type = ['Admin', 'ManagerGeneral']) => {
  const userData = getUserData()
  const ability = array && array.length ? array : userData ? userData.ability : null
  let resp = false
  if (ability && ability.length) {
    for (let i = 0; i < ability.length; i++) {
      if ((typeof type === 'string' && ability[i].subject === type) || (typeof type !== 'string' && type.includes(ability[i].subject))) {
          resp = true
          break
      }     
    }
  }
  return resp
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'system' && abilityAdminCheck()) return '/painel'
  else if (userRole === 'system' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'system' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  if (userRole === 'hospital' && abilityAdminCheck()) return '/painel'
  else if (userRole === 'hospital' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'hospital' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  if (userRole === 'healthinsurance' && abilityAdminCheck()) return '/painel'
  else if (userRole === 'healthinsurance' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'healthinsurance' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  if (userRole === 'provider' && abilityAdminCheck()) return '/painel'
  else if (userRole === 'provider' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'provider' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  return '/login'
}

export const getLoginRouteForLoggedInUser = userRole => {
  if (userRole === 'system' && abilityAdminCheck()) return '/usuarios'
  else if (userRole === 'system' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'system' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  if (userRole === 'hospital' && abilityAdminCheck()) return '/usuarios'
  else if (userRole === 'hospital' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'hospital' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  if (userRole === 'healthinsurance' && abilityAdminCheck()) return '/usuarios'
  else if (userRole === 'healthinsurance' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'healthinsurance' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'

  if (userRole === 'provider' && abilityAdminCheck()) return '/usuarios'
  else if (userRole === 'provider' && abilityAdminCheck(null, 'Financial')) return '/financeiro'
  else if (userRole === 'provider' && abilityAdminCheck(null, 'ManagerQuotes')) return '/cotacoes'
  else if (userRole === 'provider' && abilityAdminCheck(null, 'ManagerProducts')) return '/produtos'

  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const truncate = (str, num) => (str.length > num ? `${str.slice(0, num)}...` : `${str}`)

export const checkTimeDate = (time) => {
  const dateCheck = (new Date()).getTime()
  if (time > dateCheck) return true
  return false
}

export const addDayTime = (days, dateNew = null) => {
  const date = dateNew ? dateNew : new Date()
  date.setDate(date.getDate() + days)
  const dateCopy = new Date(date.getTime())
  return dateCopy.getTime()
}

export const addMinutesDate = (numOfMinutes, date = new Date()) => {
  date.setMinutes(date.getMinutes() + numOfMinutes)
  return date
}

export const chooseAbility = (val) => {
  const abilities = {
    Admin: [{ action: "read", subject: "Admin" }],
    Financial: [{ action: "read", subject: "Financial" }],
    ManagerQuotes: [{ action: "read", subject: "ManagerQuotes" }],
    ManagerGeneral: [{ action: "read", subject: "ManagerGeneral" }],
    ManagerProducts: [{ action: "read", subject: "ManagerProducts" }]
  }

  if (abilities[val]) abilities[val].push({ action: 'read', subject: 'Auth'})
  return abilities[val] ? abilities[val] : false
}

export const arrayAbilities = () => ['Admin', 'Financial', 'ManagerQuotes', 'ManagerGeneral', 'ManagerProducts']

export const getAbilityInfos = (val) => {
  const abilitiesInfos = {
    Admin: { title: 'Admin', color: 'light-success'},
    Financial: { title: 'Financial', color: 'light-info'},
    ManagerQuotes: { title: 'Manager Quotes', color: 'light-warning'},
    ManagerGeneral: { title: 'Manager General', color: 'light-primary'},
    ManagerProducts: { title: 'Manager Products', color: 'dark'}
  }

  return abilitiesInfos[val]
}

export const selectAbilityUser = (abilities = null) => {
  let resp = null
  const userData = abilities ? abilities : (getUserData()).ability
  const getAbilities = arrayAbilities()
  for (const key in userData) {
    if (getAbilities.includes(userData[key].subject)) {
      resp = userData[key].subject
    }
  }
  return resp
}

export const selectEspecificAbility = (abArray) => {
  let resp = false
  if (typeof abArray !== 'string') {
    for (const key in abArray) {
      if (abArray[key].subject === 'Admin') { resp = 'Admin'; break }
      if (abArray[key].subject === 'Financial') { resp = 'Financial'; break }
      if (abArray[key].subject === 'ManagerQuotes') { resp = 'ManagerQuotes'; break }
      if (abArray[key].subject === 'ManagerGeneral') { resp = 'ManagerGeneral'; break }
      if (abArray[key].subject === 'ManagerProducts') { resp = 'ManagerProducts'; break }
    }
  } else {
    resp = abArray
  }

  return resp
}

// ** Get Images */
// 1 - Images
// 2 - Thumbnail Images
// 3 - Docs
export const getUrlDocsImgsTypes = (val, type = 1, model = null) => {
  if (type === 1 || model) {
    return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_PATH_IMAGES}${val}`
  } else if (type === 2) {
    return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_PATH_THUMBNAILS_IMAGES}${val}`
  } else if (type === 3) {
    return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_PATH_DOCUMENTS}${val}`
  }
}

export const formatFinancialNumber = (num, type = true, curr = 'BRL', language = 'pt-BR') => num.toLocaleString(language, type ? {style: 'currency', currency: curr, minimumFractionDigits: 2} : {minimumFractionDigits: 2})
export const formatFinancialFloat = (num) => parseFloat((parseInt(num) / 100).toFixed(2))
export const formatInputFinancial = (num, type = false, curr = 'BRL', language = 'pt-BR') => formatFinancialNumber(formatFinancialFloat(num), type, curr, language)

export const checkDocumentsCompany = (docs, status = 'approved') => {
  let num = 0
  for (let i = 0; i < docs.length; i++) {
    if ((docs[i].check && docs[i].status === status) || (!docs[i].check && docs[i].status === status)) {
      num++
    }
  }
  return num
}

export const checkSystemRole = (data = null) => {
  const userData = !data ? getUserData() : data
  return userData && userData.role === 'system'
}

export const checkAbilityAndRole = (ability, ab, r) => {
  const userData = getUserData()
  return ability.can("read", ab) && userData && userData.role === r
}

export const checkPermissionRole = (roles = 'hospital', data = null) => {
  const userData = !data ? getUserData() : data
  if (typeof roles === 'string') {
    return userData && userData.role === roles
  } else {
    let check = false
    for (const key in roles) {
      if (roles[key] === userData.role) {
        check = true; break
      }
    }

    return check
  }
}

export const getInitialsTitle = str => {
  const results = []
  const wordArray = str.split(' ')
  let index = 0
  wordArray.forEach(e => {
    if (index > 1) return false
    results.push(e[0])
    index++
  })
  return results.join('')
}

export const getSelectCompany = () => {
  const userData = getUserData()
  if (userData && userData.companies) {
    let selectCompany = null
    const companies = userData.companies
    for (const key in companies) {
      if (companies[key].is_selected && companies[key].status === 'approved') {
        selectCompany = companies[key]
        break
      }
    }

    if (!selectCompany) return [] //userData.companies[0] ? userData.companies[0] : []
    else return selectCompany
  }

  return []
}

export const getSelectCompanyId = (status = 'approved') => {
  const userData = getUserData()
  if (userData && userData.companies) {
    let selectCompany = null
    const companies = userData.companies
    for (const key in companies) {
      if (companies[key].is_selected && ((status && companies[key].status === status) || !status)) {
        selectCompany = companies[key]
        break
      }
    }

    if (!selectCompany) return null //userData.companies[0] && userData.companies[0]._id ? userData.companies[0]._id : null
    else return selectCompany._id
  }

  return null
}

export const selectEspecificCompany = (companies, type = [], id = null) => {
  if (companies && companies.length) {
    let selectCompany = null
    for (const key in companies) {
      if (type.includes(companies[key].type) && (id ? (companies[key]._id === id) : !id)) {
        selectCompany = companies[key]
        break
      }
    }

    if (!selectCompany) return [] //userData.companies[0] ? userData.companies[0] : []
    else return selectCompany
  }

  return []
}

export const compareUsersIds = (id) => {
  const userData = getUserData()
  return userData._id === id
}

export const checkCompanyIsSelectUser = (id, status = null) => {
  const currentIdCompany = getSelectCompanyId(status)
  return currentIdCompany === id
}

export const checkPermissionsMaster = (userData = null) => (checkSystemRole(userData) && abilityAdminCheck(userData))
export const checkUserId = (data) => {
  const userData = getUserData()
  return userData && data && (userData._id === data._id || userData._id === data.id)
}

export const checkAllFilters = (i, v) => {
  if ((i.label && i.label.toLowerCase().includes(v.toLowerCase()))
    || (i.email && i.email.toLowerCase().includes(v.toLowerCase()))
    || (i.value && i.value.toLowerCase().includes(v.toLowerCase()))
    || (i.code && i.code.toLowerCase().includes(v.toLowerCase()))
    || (i.portfolio_number && i.portfolio_number.toLowerCase().includes(v.toLowerCase()))
    || (i.cpf && i.cpf.toLowerCase().includes(v.toLowerCase()))
    || (i.cnpj && i.cnpj.toLowerCase().includes(v.toLowerCase()))
    || (i.zipcode && i.zipcode.toLowerCase().includes(v.toLowerCase()))
    || (i.street && i.street.toLowerCase().includes(v.toLowerCase()))
    || (i.district && i.district.toLowerCase().includes(v.toLowerCase()))
    || (i.role && i.role.toLowerCase().includes(v.toLowerCase()))
    || (i.crm && i.crm.toLowerCase().includes(v.toLowerCase()))
    || (i.sku && i.sku.toLowerCase().includes(v.toLowerCase()))) {
      return true
  }

  return false
}

export const checkDataIncludes = (v, data, t = '_id') => {
  let check = false
  if (data && Array.isArray(data)) {
    for (const key in data) {
      if (data[key][t] === v) {
        check = true; break
      }
    }
  }

  return check
}

export const dataFormGetCompany = (dataForm) => (!dataForm.company_id ? (checkSystemRole() && !dataForm.id ? dataForm.company_id._id : (dataForm.id && dataForm.company_id ? dataForm.company_id : getSelectCompanyId())) : (checkSystemRole() ? (dataForm.company_id._id ? dataForm.company_id._id : dataForm.company_id) : dataForm.company_id))

export const checkProposalsIds = (proposals, status = null) => {
  let resp = false
  const company_id = getSelectCompanyId()
  if (typeof proposals !== 'string' && proposals && proposals.length) {
    for (const key in proposals) {
      if (proposals[key].company_id === company_id && (status ? status === proposals[key].status : !status)) {
        resp = true
        break
      }
    }
  }

  return resp
}

export const idsCompaniesUser = () => {
  const userData = getUserData()
  const ids = []
  if (userData.companies && userData.companies.length) {
    for (const key in userData.companies) {
      ids.push(userData.companies[key]._id)
    }
  }
  return ids
}

export const checkUsersCompanies = (companies) => {
  let resp = false
  const idsUser = idsCompaniesUser()
  if (typeof companies !== 'string' && companies && companies.length) {
    for (const key in companies) {
      if (idsUser.includes(companies[key]._id)) {
        resp = true
        break
      }
    }
  }

  return resp
}

export const prevNextMonthly = (date, act) => {  
  const dateInit  = new Date(date[0])
  const dateEnd   = new Date(date[1])
  if (act === 'next') {
    return [
      (new Date(dateInit.getFullYear(), dateInit.getMonth() + 1, 1)), 
      (new Date(dateEnd.getFullYear(), dateEnd.getMonth() + 2, 0))
    ]
  } else if (act === 'prev') {
    return [
      (new Date(dateInit.getFullYear(), dateInit.getMonth() - 1, 1)), 
      (new Date(dateEnd.getFullYear(), dateEnd.getMonth(), 0))
    ]
  }
}

export const prevNextWeek = (date, act) => {  
  const dateInit  = new Date(date[0])
  const dateEnd   = new Date(date[1])
  
  let first = null
  let last = null
  if (act === 'next') {
    first = (dateInit.getDate() - dateInit.getDay()) + 8
    last = (dateEnd.getDate() - dateEnd.getDay()) + 7
  } else if (act === 'prev') {
    first = (dateInit.getDate() - dateInit.getDay()) - 6
    last = (dateEnd.getDate() - dateEnd.getDay()) - 7
  } else {
    first = (dateInit.getDate() - dateInit.getDay())
    last = (dateEnd.getDate() - dateEnd.getDay()) + 7
  }

  return [
    (new Date(dateInit.setDate(first)).toUTCString()), 
    (new Date(dateEnd.setDate(last)).toUTCString())
  ]
}

export const firstLastDayMonthly = (value = null) => {
  const date = value ? new Date(value) : new Date()
  return [
    (new Date(date.getFullYear(), date.getMonth(), 1)), 
    (new Date(date.getFullYear(), date.getMonth() + 1, 0))
  ]
}

export const firstLastDayWeek = (value = null) => {
  const date      = value ? new Date(value) : new Date()
  const dateLast  = value ? new Date(value) : new Date()

  const first = (date.getDate() - date.getDay()) + 1
  const last  = (dateLast.getDate() - dateLast.getDay()) + 7

  return [
    (new Date(date.setDate(first)).toUTCString()), 
    (new Date(dateLast.setDate(last)).toUTCString())
  ]
}

export const getTypeFiles = (files, type = 'post_surgery') => {
  let resp = null
  for (const key in files) {
    if (files[key].input === type) {
      resp = files[key]
      break
    }
  }

  return resp
}

export const formatUpdateUser = (data, type = null) => {
  const userData = getUserData()
  let obj = null
  let token = null

  if (type === 'contacts') {
    obj = {...userData}
    obj.contacts = {}
    obj.contacts = {...data}
  } else if (type === 'address') {
    obj = {...userData}
    obj.address = {}
    obj.address = {...data}
  } else if (type === 'companies')  {
    obj = {...userData}
    obj.companies = {}
    obj.companies = {...data}
  } else if (type === 'check' || type === 'check_url')  {
    obj = {...data.data}
    obj.accessToken = data.token
    obj.refreshToken = data.token
    token = data.token
  } else {
    obj = {...data}
  }

  let dataRefresh = { ...obj, accessToken: token ? token : userData.accessToken, refreshToken: token ? token : userData.refreshToken }
  if (userData.accessTokenAdmin) {
    dataRefresh = { ...obj, accessToken: token ? token : userData.accessToken, refreshToken: token ? token : userData.refreshToken, accessTokenAdmin: userData.accessTokenAdmin }
  }

  return dataRefresh
}

export const checkStatusPermission = (row, roles) => (roles.includes(row.status))

export const typeDocuments = (t) => {
  return {
    not_found: { title: t('Not Found'), color: 'light-dark' },
    company: { title: t('Company'), color: 'light-info' },
    proposal: { title: t('Proposal'), color: 'light-warning' },
    product: { title: t('Product'), color: 'light-primary' },
    user: { title: t('User'), color: 'light-danger' },
    order: { title: t('Order'), color: 'light-info' },
    quote: { title: t('Quotation'), color: 'light-info' }
  }
}

export const inputTypeDocuments = (t) => {
  return {
    not_found: { title: t('Not Found'), color: 'light-dark' },
    invoice: { title: t('Invoice'), color: 'light-danger' },
    post_surgery: { title: t('Post Surgery'), color: 'light-danger' },
    social_contract: { title: t('Contrato Social'), color: 'light-info' },
    cnpj_card: { title: t('CNPJ Card'), color: 'light-warning' },
    operating_license: { title: t('Operating License'), color: 'light-primary' },
    health_surveillance: { title: t('Health Surveillance'), color: 'light-success' },
    technical_responsability: { title: t('Technical Responsability'), color: 'light-danger' },

    invoice_provider: { title: t('Invoice Provider'), color: 'light-success' },
    boleto_provider: { title: t('Boleto Provider'), color: 'light-success' },
    invoice_company: { title: t('Invoice Company'), color: 'light-success' },
    boleto_company: { title: t('Boleto Company'), color: 'light-success' },
    invoice_file_company: { title: t('Invoice Excel File'), color: 'light-success' },
    purchase_order: { title: t('Purchase Order'), color: 'light-success' }
  }
}

export const statusDocuments = (t) => {
  return  {
    waiting_approval: { title: t('Waiting Approval'), color: 'warning' },
    approved: { title: t('Approved'), color: 'success' },
    disapproved: { title: t('Disapproved'), color: 'danger' },
    pending: { title: t('Pending'), color: 'info' }
  }
}

export const statusQuotes = (t, type = 1) => {
  let status = null
  if (type === 1) {
    status = {
      open_proposals: { title: t("Open to Proposals"), color: 'light-success' },
      receiving_proposals: { title: t("Receiving Proposals"), color: 'light-warning' },
      schedule_surgery: { title: t("Schedule Surgery"), color: 'light-info' },
      scheduled_surgery: { title: t("Scheduled Surgery"), color: 'light-info' },
      awaiting_surgery: { title: t("Awaiting Surgery"), color: 'light-warning' },
      surgery_done: { title: t("Surgery Done"), color: 'light-info' },
      waiting_post_surgery: { title: t("Waiting Post Surgery"), color: 'light-warning' },
      checking_post_surgery: { title: t("Checking Post Surgery"), color: 'light-warning' },
      awaiting_reply: { title: t('Awaiting Reply'), color: 'light-warning' },
      waiting_billing: { title: t("Release Billing"), color: 'light-warning' },
      release_billing: { title: t("Release Billing"), color: 'success' },
      release_billing_system: { title: t("Release Billing"), color: 'success' },
      completed: { title: t("Completed"), color: 'light-success' },
      finished: { title: t("Finished"), color: 'light-success' },
      closed: { title: t('Closed'), color: 'light-danger'}
    }
  } else if (type === 2) {
    status = [
      { value: 'open_proposals', label: t("Open to Proposals"), color: 'light-success' },
      { value: 'receiving_proposals', label: t("Receiving Proposals"), color: 'light-warning' },
      { value: 'schedule_surgery', label: t("Schedule Surgery"), color: 'light-info' },
      { value: 'scheduled_surgery', label: t("Scheduled Surgery"), color: 'light-success' },
      { value: 'awaiting_surgery', label: t("Awaiting Surgery"), color: 'light-warning' },
      { value: 'surgery_done', label: t("Surgery Done"), color: 'light-info' },
      { value: 'waiting_post_surgery', label: t("Waiting Post Surgery"), color: 'light-warning' },
      { value: 'checking_post_surgery', label: t("Checking Post Surgery"), color: 'light-warning' },
      { value: 'awaiting_reply', label: t('Awaiting Reply'), color: 'light-warning' },
      { value: 'waiting_billing', label: t("Waiting Billing"), color: 'success' },
      { value: 'release_billing', label: t("Release Billing"), color: 'success' },
      { value: 'completed', label: t("Completed"), color: 'light-success' },
      { value: 'finished', label: t("Finished"), color: 'light-success' },
      { value: 'closed', label: t('Closed'), color: 'light-danger'}
    ]
  }

  return status
}

export const statusProposals = (t, type = 1) => {
  let status = null
  if (type === 1) {
    status = {
      open_proposal: { title: t("Open Proposal"), color: 'light-success' },
      proposal_accepted: { title: t("Proposal Accepted"), color: 'light-success' },
      schedule_surgery: { title: t("Schedule Surgery"), color: 'light-info' },
      scheduled_surgery: { title: t("Scheduled Surgery"), color: 'light-info' },
      awaiting_surgery: { title: t("Awaiting Surgery"), color: 'light-warning' },
      surgery_done: { title: t("Surgery Done"), color: 'light-info' },
      waiting_post_surgery: { title: t("Waiting Post Surgery"), color: 'light-warning' },
      checking_post_surgery: { title: t("Checking Post Surgery"), color: 'light-warning' },
      waiting_billing: { title: t("Waiting Billing"), color: 'light-warning' },
      release_billing: { title: t("Release Billing"), color: 'success' },
      release_billing_system: { title: t("Release Billing"), color: 'success' },
      finished: { title: t("Finished"), color: 'light-success' },
      refused: { title: t("Refused"), color: 'light-danger' }
    }
  } else if (type === 2) {
    status = [
      { value: 'open_proposal', label: t("Open Proposal"), color: 'light-success' },
      { value: 'proposal_accepted', label: t("Proposal Accepted"), color: 'light-success' },
      { value: 'schedule_surgery', label: t("Schedule Surgery"), color: 'light-info' },
      { value: 'scheduled_surgery', label: t("Scheduled Surgery"), color: 'light-success' },
      { value: 'awaiting_surgery', label: t("Awaiting Surgery"), color: 'light-warning' },
      { value: 'surgery_done', label: t("Surgery Done"), color: 'light-info' },
      { value: 'waiting_post_surgery', label: t("Waiting Post Surgery"), color: 'light-warning' },
      { value: 'checking_post_surgery', label: t("Checking Post Surgery"), color: 'light-warning' },
      { value: 'waiting_billing', label: t("Waiting Billing"), color: 'light-warning' },
      { value: 'release_billing', label: t("Release Billing"), color: 'success' },
      { value: 'finished', label: t("Finished"), color: 'light-success' },
      { value: 'refused', label: t("Refused"), color: 'light-danger' }
    ]
  }

  return status
}

export const statusOrders = (t, type = 1) => {
  let status = null
  if (type === 1) {
    status = {
      schedule_surgery: { title: t("Schedule Surgery"), color: 'light-info' },
      scheduled_surgery: { title: t("Scheduled Surgery"), color: 'light-info' },
      awaiting_surgery: { title: t("Awaiting Surgery"), color: 'light-warning' },
      surgery_done: { title: t("Surgery Done"), color: 'light-info' },
      waiting_post_surgery: { title: t("Waiting Post Surgery"), color: 'light-warning' },
      checking_post_surgery: { title: t("Checking Post Surgery"), color: 'light-warning' },
      waiting_billing: { title: t("Release Billing"), color: 'success' },
      release_billing: { title: t("Release Billing"), color: 'success' },
      release_billing_system: { title: t("Release Billing"), color: 'success' },
      refused: { title: t("Refused"), color: 'light-danger' }
    }
  } else if (type === 2) {
    status = [
      { value: 'schedule_surgery', label: t("Schedule Surgery"), color: 'light-info' },
      { value: 'scheduled_surgery', label: t("Scheduled Surgery"), color: 'light-success' },
      { value: 'awaiting_surgery', label: t("Awaiting Surgery"), color: 'light-warning' },
      { value: 'surgery_done', label: t("Surgery Done"), color: 'light-info' },
      { value: 'waiting_post_surgery', label: t("Waiting Post Surgery"), color: 'light-warning' },
      { value: 'checking_post_surgery', label: t("Checking Post Surgery"), color: 'light-warning' },
      { value: 'waiting_billing', label: t("Waiting Billing"), color: 'light-warning' },
      { value: 'release_billing', label: t("Release Billing"), color: 'success' },
      { value: 'refused', label: t("Refused"), color: 'light-danger' }
    ]
  }

  return status
}

export const brazilStates = () => {
  return [
    {label: "Acre", value: "AC"},
    {label: "Alagoas", value: "AL"},
    {label: "Amapá", value: "AP"},
    {label: "Amazonas", value: "AM"},
    {label: "Bahia", value: "BA"},
    {label: "Ceará", value: "CE"},
    {label: "Distrito Federal", value: "DF"},
    {label: "Espírito Santo", value: "ES"},
    {label: "Goiás", value: "GO"},
    {label: "Maranhão", value: "MA"},
    {label: "Mato Grosso", value: "MT"},
    {label: "Mato Grosso do Sul", value: "MS"},
    {label: "Minas Gerais", value: "MG"},
    {label: "Pará", value: "PA"},
    {label: "Paraíba", value: "PB"},
    {label: "Paraná", value: "PR"},
    {label: "Pernambuco", value: "PE"},
    {label: "Piauí", value: "PI"},
    {label: "Rio de Janeiro", value: "RJ"},
    {label: "Rio Grande do Norte", value: "RN"},
    {label: "Rio Grande do Sul", value: "RS"},
    {label: "Rondônia", value: "RO"},
    {label: "Roraima", value: "RR"},
    {label: "Santa Catarina", value: "SC"},
    {label: "São Paulo", value: "SP"},
    {label: "Sergipe", value: "SE"},
    {label: "Tocantins", value: "TO"}
  ]
}