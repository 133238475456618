// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Contracts Paginate
export const getContractsPaginate = createAsyncThunk('contracts/getContractsPaginate', async (params) => {
  const response = await api
  .get(`contracts/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Contract
export const getContract = createAsyncThunk('contracts/getContract', async (id) => {
  const response = await api
  .get(`contract/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const getContractsInputList = createAsyncThunk('contracts/getContractsInputList', async (params) => {
  const response = await api
  .get(`contracts/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Contract
export const addContract = createAsyncThunk('contracts/addContract', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('contract', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Contract
export const updateContract = createAsyncThunk('contracts/updateContract', async (data) => {
  const response = await api
  .put(`contract/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Status Contract
export const updateStatusContract = createAsyncThunk('contracts/updateStatusContract', async (id) => {
  const response = await api
  .put(`contract/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Status Contract
export const updateIsPublicContract = createAsyncThunk('contracts/updateIsPublicContract', async (id) => {
  const response = await api
  .put(`contract/ispublic/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Contract
export const deleteContract = createAsyncThunk('contracts/deleteContract', async (id) => {
  const response = await api
  .delete(`contract/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getContractsPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(getContract.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.allData   = []
        state.data      = []
        state.total     = null
      })
      .addCase(getContractsInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addContract.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteContract.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
  }
})

export default contractsSlice.reducer