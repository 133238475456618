import { lazy } from 'react'

const Dashboard           = lazy(() => import('../../views/dashboard'))
const Users               = lazy(() => import('../../views/users'))
const Profile             = lazy(() => import('../../views/users/AccountSettings'))
const RegisterEditUsers   = lazy(() => import('../../views/users/RegisterEditUsers'))
const Companies           = lazy(() => import('../../views/companies'))
const CompaniesDocuments  = lazy(() => import('../../views/companies/documents'))
const CompaniesRegisterEdit = lazy(() => import('../../views/companies/RegisterEditCompanies'))
const Contracts           = lazy(() => import('../../views/contracts'))
const ContractsRegisterEdit = lazy(() => import('../../views/contracts/RegisterEditContracts'))
const SystemSettings      = lazy(() => import('../../views/settings/system'))
const Quotes              = lazy(() => import('../../views/quotes'))
const QuotesRegisterEdit  = lazy(() => import('../../views/quotes/RegisterEditQuotes'))
const Proposals           = lazy(() => import('../../views/proposals'))
const Products            = lazy(() => import('../../views/products'))
const ProductsRegisterEdit  = lazy(() => import('../../views/products/RegisterEditProducts'))
const Customers           = lazy(() => import('../../views/customers'))
const Procedures          = lazy(() => import('../../views/procedures'))
const Hospitals           = lazy(() => import('../../views/hospitals'))
const HealthInsurances    = lazy(() => import('../../views/healthinsurances'))
const Manufacturers       = lazy(() => import('../../views/manufacturers'))
const Categories          = lazy(() => import('../../views/categories'))
const Financial           = lazy(() => import('../../views/financial'))
const BillingCompanies    = lazy(() => import('../../views/billingcompanies'))

const UsersMenus = [
  {
    path: '/painel',
    element: <Dashboard />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/perfil',
    element: <Profile />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: 'Auth'
    }
  },
  {
    path: '/usuarios',
    element: <Users />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/usuarios/:id',
    element: <RegisterEditUsers />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/usuarios/novo',
    element: <RegisterEditUsers />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/empresas',
    element: <Companies />,
    meta: {
      role: ['provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/empresas/listar',
    element: <Companies />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/empresas/documentos',
    element: <CompaniesDocuments />,
    meta: {
      role: ['system'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/empresas/nova',
    element: <CompaniesRegisterEdit />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/empresas/:id',
    element: <CompaniesRegisterEdit />,
    meta: {
      role: ['system', 'provider', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/produtos/novo',
    element: <ProductsRegisterEdit />,
    meta: {
      role: ['system', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerProducts']
    }
  },
  {
    path: '/produtos/:id',
    element: <ProductsRegisterEdit />,
    meta: {
      role: ['system', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerProducts']
    }
  },
  {
    path: '/produtos/listar',
    element: <Products />,
    meta: {
      role: ['system'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerProducts']
    }
  },
  {
    path: '/produtos',
    element: <Products />,
    meta: {
      role: ['system', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerProducts']
    }
  },
  {
    path: '/cotacoes/:id',
    element: <QuotesRegisterEdit />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/cotacoes/nova',
    element: <QuotesRegisterEdit />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/cotacoes',
    element: <Quotes />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/propostas',
    element: <Proposals />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/clientes',
    element: <Customers />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/procedimentos',
    element: <Procedures />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/hospitais',
    element: <Hospitals />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/convenios',
    element: <HealthInsurances />,
    meta: {
      role: ['system', 'hospital', 'healthinsurance'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/fabricantes',
    element: <Manufacturers />,
    meta: {
      role: ['system', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/categorias',
    element: <Categories />,
    meta: {
      role: ['system', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
    }
  },
  {
    path: '/empresas-faturamento',
    element: <BillingCompanies />,
    meta: {
      role: ['system'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/contratos',
    element: <Contracts />,
    meta: {
      role: ['system'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/contratos/:id',
    element: <ContractsRegisterEdit />,
    meta: {
      role: ['system'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/contratos/novo',
    element: <ContractsRegisterEdit />,
    exactly: true,
    meta: {
      role: ['system'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral']
    }
  },
  {
    path: '/financeiro',
    element: <Financial />,
    exactly: true,
    meta: {
      role: ['system', 'hospital', 'healthinsurance', 'provider'],
      action: 'read',
      resource: ['Admin', 'ManagerGeneral', 'Financial']
    }
  },
  {
    path: '/configuracoes/sistema',
    element: <SystemSettings />,
    meta: {
      role: ['system'],
      action: 'read',
      resource: 'Admin'
    }
  }
]

export default UsersMenus