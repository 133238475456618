import axios from 'axios'

// ** Configs
import themeConfig from '@configs/themeConfig'

// ** Utils
import { getUserData } from '@utils'

const userData = getUserData()

const api = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` })

api.interceptors.request.use(
  request => {
    const language = {
      pt: "pt-BR,br,pt;",
      en: "en-Us,en;",
      es: "pt-BR,br,pt;",
      br: "pt-BR,br,pt;"
    }
    // ** Get token from localStorage
    const accessToken = userData ? userData.accessToken : ""
    const accessTokenAdmin = userData && userData.accessTokenAdmin ? userData.accessTokenAdmin : null

    // ** If token is present add it to request's Authorization Header
    if (accessToken) request.headers['x-access-token'] = `${accessToken}`
    if (accessTokenAdmin) request.headers['x-access-token-admin'] = `${accessTokenAdmin}`
    request.headers['accept-language'] = language[themeConfig.language]

    return request
  },
  error => Promise.reject(error)
)

export default api