// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Users Paginate
export const getUsersPaginate = createAsyncThunk('users/getUsersPaginate', async (params) => {
  const response = await api
  .get(`users/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET User
export const getUser = createAsyncThunk('users/getUser', async (id) => {
  const response = await api
  .get(`user/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getUsersInputList = createAsyncThunk('users/getUsersInputList', async (params) => {
  const response = await api
  .get(`users/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** Hash GET User
export const getHash = createAsyncThunk('users/getHash', async (params) => {
  const response = await api
  .get(`user/hash/${params.hash}`, { params })
  .catch((err) => console.log(err))

  return response.data
})

// ** Hash Create User
export const createHash = createAsyncThunk('users/createHash', async (email) => {
  const response = await api
  .put(`user/hash/${email}`)
  .catch((err) => console.log(err))

  return response.data
})

//** Reset Password User
export const resetPassword = createAsyncThunk('users/resetPassword', async (params) => {
  const response = await api
  .post(`user/reset-password/${params.hash}`, params.dataForm)
  .catch((err) => console.log(err))

  return response.data.data
})

//** Add User
export const addUser = createAsyncThunk('users/addUser', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('user', data)
  .catch(err => console.log(err))
  
  return response.data
})

// ** Update User
export const updateUser = createAsyncThunk('users/updateUser', async (data) => {
  const response = await api
  .put(`user/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

export const updatePassword = createAsyncThunk('users/updatePassword', async (data) => {
  const response = await api
  .put(`user/password-update/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete User
export const deleteUser = createAsyncThunk('users/deleteUser', async (id) => {
  const response = await api
  .delete(`user${id ? `/${id}` : ''}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Search User
export const searchUser = createAsyncThunk('users/searchUser', async (val) => {
  const response = await api
  .get(`user/cpf/${val}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Login for User
export const loginForUser = createAsyncThunk('users/loginForUser', async (id) => {
  const response = await api
  .post(`user/login/${id}`)
  .catch(err => console.log(err))

  return response.data
})

//** Check Hash Code 
export const checkHashCode = createAsyncThunk('users/checkHashCode', async (data) => {
  const response = await api
  .get(`user/hash/code/${data.code}`)
  .catch((err) => console.log(err))

  return response.data
})

//** Resend Code
export const resendHashCode = createAsyncThunk('users/resendHashCode', async (id) => {
  const response = await api
  .put(`user/hash/code/${id}`)
  .catch((err) => console.log(err))

  return response.data
})

//** Resend Code
export const settingNotifications = createAsyncThunk('users/settingNotifications', async (data) => {
  const response = await api
  .put(`user/notifications/${data.id}`, data)
  .catch((err) => console.log(err))

  return response.data
})

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    notifications: null,
    resetPassword: null,
    selectedSearch: null,
    selectedPostCode: null,
    selectedPostCodeStatus: null,
    checkCode: null,
    resendCode: null,
    inputList: null,
    passwordUpdated: null
  },
  reducers: {
    removeUpdatePassword: (state, action) => {
      const payload = action.payload
      state.passwordUpdated = payload.passwordUpdated ? state.passwordUpdated : null
    },
    handleRemove: (state, action) => {
      const payload = action.payload
      state.updated = payload.updated ? state.updated : null
      state.added = payload.added ? state.added : null
      state.deleted = payload.deleted ? state.deleted : null
    },
    handleRemoveNotifications: (state, action) => {
      const payload = action.payload
      state.notifications = payload.notifications ? state.notifications : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUsersPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.allData   = []
        state.data      = []
        state.total     = null
      })
      .addCase(getUsersInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addUser.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.passwordUpdated = action.payload
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.added             = null
        state.updated           = null
        state.selectedSearch    = action.payload
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword = action.payload
        state.checkCode     = null
        state.resendCode    = null
      })
      .addCase(getHash.fulfilled, (state, action) => {
        state.hash = action.payload.data
        state.errorHash = action.payload && action.payload.error ? action.payload.error : null
      })
      .addCase(createHash.fulfilled, (state, action) => {
        state.hash = action.payload.data ? action.payload.data : ""
      })
      .addCase(checkHashCode.fulfilled, (state, action) => {
        state.resendCode    = null
        state.checkCode     = action.payload
      })
      .addCase(resendHashCode.fulfilled, (state, action) => {
        state.checkCode     = null
        state.resendCode    = action.payload
      })
      .addCase(loginForUser.fulfilled, (state, action) => {
        state.login   = action.payload
      })
      .addCase(settingNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload
      })
  }
})

export const { removeUpdatePassword, handleRemove, handleRemoveNotifications } = usersSlice.actions

export default usersSlice.reducer