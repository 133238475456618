// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Companies Paginate
export const getAddressCompaniesPaginate = createAsyncThunk('companies_addresses/getAddressCompaniesPaginate', async (params) => {
  const response = await api
  .get(`address-companies/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Company Address
export const getAddressCompany = createAsyncThunk('companies_addresses/getAddressCompany', async (id) => {
  const response = await api
  .get(`address-companies/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

//** Add Company Address
export const addAddressCompany = createAsyncThunk('companies_addresses/addAddressCompany', async (data) => {
  const response = await api
  .put('address-companies', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Company Address
export const updateAddressCompany = createAsyncThunk('companies_addresses/updateAddressCompany', async (data) => {
  const response = await api
  .put(`address-companies/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Company Address Status
export const updateAddressStatusCompany = createAsyncThunk('companies_addresses/updateAddressStatusCompany', async (id) => {
  const response = await api
  .put(`address-companies/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Company Address
export const deleteAddressCompany = createAsyncThunk('companies_addresses/deleteAddressCompany', async (id) => {
  const response = await api
  .delete(`address-companies/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('companies_addresses/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const companiesAddressSlice = createSlice({
  name: 'companies_addresses',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    selectedSearch: null,
    selectedPostCode: null,
    selectedPostCodeStatus: null
  },
  reducers: {
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAddressCompaniesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(getAddressCompany.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.allData   = []
        state.data      = []
        state.total     = null
      })
      .addCase(addAddressCompany.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateAddressCompany.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteAddressCompany.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
        state.checkCode                 = null
        state.resendCode                = null
      })
  }
})

export const { removePostCodeSearch } = companiesAddressSlice.actions

export default companiesAddressSlice.reducer