// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET BillingCompanies Paginate
export const getBillingCompaniesPaginate = createAsyncThunk('billingcompanies/getBillingCompaniesPaginate', async (params) => {
  const response = await api
  .get(`billingcompanies/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET BillingCompany
export const getBillingCompany = createAsyncThunk('billingcompanies/getBillingCompany', async (id) => {
  const response = await api
  .get(`billingcompany/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getBillingCompaniesInputList = createAsyncThunk('billingcompanies/getBillingCompaniesInputList', async (params) => {
  const response = await api
  .get(`billingcompanies/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add BillingCompany
export const addBillingCompany = createAsyncThunk('billingcompanies/addBillingCompany', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('billingcompany', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update BillingCompany
export const updateBillingCompany = createAsyncThunk('billingcompanies/updateBillingCompany', async (data) => {
  const response = await api
  .put(`billingcompany/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking BillingCompany
export const updateStatusBillingCompany = createAsyncThunk('billingcompanies/updateStatusBillingCompany', async (id) => {
  const response = await api
  .put(`billingcompany/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete BillingCompany
export const deleteBillingCompany = createAsyncThunk('billingcompanies/deleteBillingCompany', async (id) => {
  const response = await api
  .delete(`billingcompany/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('billingcompanies/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const billingcompaniesSlice = createSlice({
  name: 'billingcompanies',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null,
    selectedSearch: null,
    selectedPostCode: null,
    selectedPostCodeStatus: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    },
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBillingCompaniesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getBillingCompany.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getBillingCompaniesInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addBillingCompany.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
      })
      .addCase(updateBillingCompany.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteBillingCompany.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
      })
  }
})

export const { handleRemove, removePostCodeSearch } = billingcompaniesSlice.actions

export default billingcompaniesSlice.reducer