// ** React Imports
import { useEffect } from 'react'

// ** Store Imports
import { handleLanguage } from '@store/language'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

export const useLanguage = () => {
  // ** Hooks
  const dispatch = useDispatch(),
        language = useSelector(state => state.language)

  // ** Hooks
  const { i18n } = useTranslation()

  const htmlTradutor = {
    pt: 'pt-BR',
    br: 'pt-BR',
    en: 'en',
    es: 'es'
  }

  const setTag = lang => {
    const tag = document.querySelector('html')
    tag.setAttribute('lang', htmlTradutor[lang])
  }

  const setLanguage = lang => {
    dispatch(handleLanguage(lang))
    i18n.changeLanguage(lang)
    setTag(lang) 
  }

  useEffect(() => {
    if (language && language.language && language.language !== i18n.language) {
      i18n.changeLanguage(language.language)
      setTag(language.language) 
    }
  }, [language.language])

  return { language: language.language, setLanguage, setTag }
}
