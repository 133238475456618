// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Users Paginate
export const getContactsUsersPaginate = createAsyncThunk('users_contacts/getContactsUsersPaginate', async (params) => {
  const response = await api
  .get(`contacts/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET User Contacts
export const getContactsUser = createAsyncThunk('users_contacts/getContactsUser', async (id) => {
  const response = await api
  .get(`contact/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

//** Add User Contacts
export const addContactsUser = createAsyncThunk('users_contacts/addContactsUser', async (data) => {
  const response = await api
  .put('contact', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update User Contacts
export const updateContactsUser = createAsyncThunk('users_contacts/updateContactsUser', async (data) => {
  const response = await api
  .put(`contact/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update User Contacts Status
export const updateContactsStatusUser = createAsyncThunk('users_contacts/updateContactsStatusUser', async (id) => {
  const response = await api
  .put(`contact/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete User Contacts
export const deleteContactsUser = createAsyncThunk('users_contacts/deleteContactsUser', async (id) => {
  const response = await api
  .delete(`contact/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const usersContactsSlice = createSlice({
  name: 'users_contacts',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    selectedSearch: null,
    selectedPostCode: null,
    selectedPostCodeStatus: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getContactsUsersPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(getContactsUser.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.allData   = []
        state.data      = []
        state.total     = null
      })
      .addCase(addContactsUser.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateContactsUser.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteContactsUser.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
  }
})

export default usersContactsSlice.reducer