// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Users Paginate
export const getAddressUsersPaginate = createAsyncThunk('users_addresses/getAddressUsersPaginate', async (params) => {
  const response = await api
  .get(`address/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET User Address
export const getAddressUser = createAsyncThunk('users_addresses/getAddressUser', async (id) => {
  const response = await api
  .get(`address/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

//** Add User Address
export const addAddressUser = createAsyncThunk('users_addresses/addAddressUser', async (data) => {
  const response = await api
  .put('address', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update User Address
export const updateAddressUser = createAsyncThunk('users_addresses/updateAddressUser', async (data) => {
  const response = await api
  .put(`address/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update User Address Status
export const updateAddressStatusUser = createAsyncThunk('users_addresses/updateAddressStatusUser', async (id) => {
  const response = await api
  .put(`address/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete User Address
export const deleteAddressUser = createAsyncThunk('users_addresses/deleteAddressUser', async (id) => {
  const response = await api
  .delete(`address/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('users_addresses/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const usersAddressSlice = createSlice({
  name: 'users_addresses',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    selectedSearch: null,
    selectedPostCode: null,
    selectedPostCodeStatus: null
  },
  reducers: {
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAddressUsersPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(getAddressUser.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.allData   = []
        state.data      = []
        state.total     = null
      })
      .addCase(addAddressUser.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateAddressUser.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteAddressUser.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
        state.checkCode                 = null
        state.resendCode                = null
    })
  }
})

export const { removePostCodeSearch } = usersAddressSlice.actions

export default usersAddressSlice.reducer