// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Hospitals Paginate
export const getHospitalsPaginate = createAsyncThunk('hospitals/getHospitalsPaginate', async (params) => {
  const response = await api
  .get(`hospitals/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Hospital
export const getHospital = createAsyncThunk('hospitals/getHospital', async (id) => {
  const response = await api
  .get(`hospital/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getHospitalsInputList = createAsyncThunk('hospitals/getHospitalsInputList', async (params) => {
  const response = await api
  .get(`hospitals/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Hospital
export const addHospital = createAsyncThunk('hospitals/addHospital', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('hospital', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Hospital
export const updateHospital = createAsyncThunk('hospitals/updateHospital', async (data) => {
  const response = await api
  .put(`hospital/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Hospital
export const updateStatusHospital = createAsyncThunk('hospitals/updateStatusHospital', async (id) => {
  const response = await api
  .put(`hospital/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Hospital
export const deleteHospital = createAsyncThunk('hospitals/deleteHospital', async (id) => {
  const response = await api
  .delete(`hospital/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('hospitals/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const hospitalsSlice = createSlice({
  name: 'hospitals',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    },
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getHospitalsPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getHospital.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getHospitalsInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addHospital.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateHospital.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteHospital.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
      })
  }
})

export const { handleRemove, removePostCodeSearch } = hospitalsSlice.actions

export default hospitalsSlice.reducer