// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Companies Paginate
export const getCompaniesPaginate = createAsyncThunk('companies/getCompaniesPaginate', async (params) => {
  const response = await api
  .get(`companies/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Company
export const getCompany = createAsyncThunk('companies/getCompany', async (id) => {
  const response = await api
  .get(`company/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getCompaniesInputList = createAsyncThunk('companies/getCompaniesInputList', async (params) => {
  const response = await api
  .get(`companies/input-list`, { params })
  .catch(err => console.log(err))

  response.data.params = params

  return response.data
})

//** Add Company
export const addCompany = createAsyncThunk('companies/addCompany', async (params) => { // , { dispatch, getState }
  const response = await api
  .put('company', params.data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Company
export const updateCompany = createAsyncThunk('companies/updateCompany', async (params) => {
  const response = await api
  .put(`company/${params.id}`, params.data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Company
export const updateCheckingCompany = createAsyncThunk('companies/updateCheckingCompany', async (id) => {
  const response = await api
  .put(`company/checking/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Company
export const deleteCompany = createAsyncThunk('companies/deleteCompany', async (id) => {
  const response = await api
  .delete(`company/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null,
    inputListProviders: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompaniesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.allData   = []
        state.data      = []
        state.total     = null
      })
      .addCase(getCompaniesInputList.fulfilled, (state, action) => {
        if (action.payload.params.access === 'provider') state.inputListProviders = action.payload.data
        else state.inputList = action.payload.data
      }) 
      .addCase(addCompany.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
  }
})

export default companiesSlice.reducer