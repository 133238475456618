// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialAvatar = () => {
  const item = JSON.parse(window.localStorage.getItem('userData'))
  //** Parse stored json or if none return initialValue
  return item && item.avatar ? item.avatar : null
}

export const avatarSlice = createSlice({
  name: 'avatar',
  initialState: {
    avatar: initialAvatar()
  },
  reducers: {
    handleAvatar: (state, action) => {
      state.avatar = action.payload
    }
  }
})

export const { handleAvatar } = avatarSlice.actions

export default avatarSlice.reducer
