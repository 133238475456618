// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Manufacturers Paginate
export const getManufacturersPaginate = createAsyncThunk('manufacturers/getManufacturersPaginate', async (params) => {
  const response = await api
  .get(`manufacturers/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Manufacturer
export const getManufacturer = createAsyncThunk('manufacturers/getManufacturer', async (id) => {
  const response = await api
  .get(`manufacturer/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getManufacturersInputList = createAsyncThunk('manufacturers/getManufacturersInputList', async (params) => {
  const response = await api
  .get(`manufacturers/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Manufacturer
export const addManufacturer = createAsyncThunk('manufacturers/addManufacturer', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('manufacturer', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Manufacturer
export const updateManufacturer = createAsyncThunk('manufacturers/updateManufacturer', async (data) => {
  const response = await api
  .put(`manufacturer/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Manufacturer
export const updateStatusManufacturer = createAsyncThunk('manufacturers/updateStatusManufacturer', async (id) => {
  const response = await api
  .put(`manufacturer/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Manufacturer
export const deleteManufacturer = createAsyncThunk('manufacturers/deleteManufacturer', async (id) => {
  const response = await api
  .delete(`manufacturer/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('manufacturers/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    },
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getManufacturersPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getManufacturer.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getManufacturersInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addManufacturer.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
      })
      .addCase(updateManufacturer.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteManufacturer.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
      })
  }
})

export const { handleRemove, removePostCodeSearch } = manufacturersSlice.actions

export default manufacturersSlice.reducer