// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from '@src/api'

export const getSettings = createAsyncThunk('settings/getSettings', async (name) => {
    const response = await api
    .get(`setting/${name}`)
    .catch(err => console.log(err))

    return response.data.data
})

export const updateSettings = createAsyncThunk('settings/updateSettings', async (params) => {
    const response = await api
    .put(`setting/${params.name}`, params)
    .catch(err => console.log(err))

    return response.data.data
})

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        allData: [],
        data: [],
        params: {},
        updated: null,
        selected: null,
        updatedCrawler: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(getSettings.fulfilled, (state, action) => {
            state.selected          = action.payload
            state.updated           = null
            state.updatedCrawler    = null
        })
        .addCase(updateSettings.fulfilled, (state, action) => {
            state.updated           = action.payload
            state.updatedCrawler    = null
        })
    }
})

export default settingsSlice.reducer