import { Fragment } from 'react'

import { useCompany } from '@hooks/useCompany'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledTooltip } from 'reactstrap'

// ** Custom Components
import Avatar from '@components/avatar'

import { getInitialsTitle, getUrlDocsImgsTypes } from '@utils'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'

const IntlDropdown = () => {
  // ** Hooks
  const { company, companies, setCompany } = useCompany()

  // ** Function to switch Language
  const handleCompanyUpdate = (e, id) => {
    e.preventDefault()
    setCompany(id)
  }

  return company && company._id ? (
    <UncontrolledDropdown href='/' tag='li' className='nav-item'>
      { company ? (
        <Fragment>
        <DropdownToggle id='select-company-top' href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
          <Avatar status={false} img={company.avatar ? getUrlDocsImgsTypes(company.avatar, 2) : defaultAvatar} imgHeight='23' imgWidth='23' />
          <span className='selected-language ms-1'>{getInitialsTitle(company.label)}</span>
        </DropdownToggle>
        <UncontrolledTooltip placement='bottom' target={`select-company-top`}>
          {company.label}
        </UncontrolledTooltip>
        </Fragment>
      ) : ('')}
      { companies && companies.length > 1 ? (
        <DropdownMenu className='mt-0' end>
        { companies.map((item) => {
          <DropdownItem key={item._id} href='/' tag='a' className={item._id === company._id ? 'active' : ''} onClick={(e) => handleCompanyUpdate(e, item._id)}>
            <Avatar status={false} img={item.avatar ? getUrlDocsImgsTypes(item.avatar, 2) : defaultAvatar} imgHeight='23' imgWidth='23' />
            <span className='ms-1'>{item.label}</span>
          </DropdownItem>
        }) }
        </DropdownMenu>
      ) : ('')}
    </UncontrolledDropdown>
  ) : ('')
}

export default IntlDropdown
