// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET HealthInsurances Paginate
export const getHealthInsurancesPaginate = createAsyncThunk('healthinsurances/getHealthInsurancesPaginate', async (params) => {
  const response = await api
  .get(`healthinsurances/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET HealthInsurance
export const getHealthInsurance = createAsyncThunk('healthinsurances/getHealthInsurance', async (id) => {
  const response = await api
  .get(`healthinsurance/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getHealthInsurancesInputList = createAsyncThunk('healthinsurances/getHealthInsurancesInputList', async (params) => {
  const response = await api
  .get(`healthinsurances/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add HealthInsurance
export const addHealthInsurance = createAsyncThunk('healthinsurances/addHealthInsurance', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('healthinsurance', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update HealthInsurance
export const updateHealthInsurance = createAsyncThunk('healthinsurances/updateHealthInsurance', async (data) => {
  const response = await api
  .put(`healthinsurance/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking HealthInsurance
export const updateStatusHealthInsurance = createAsyncThunk('healthinsurances/updateStatusHealthInsurance', async (id) => {
  const response = await api
  .put(`healthinsurance/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete HealthInsurance
export const deleteHealthInsurance = createAsyncThunk('healthinsurances/deleteHealthInsurance', async (id) => {
  const response = await api
  .delete(`healthinsurance/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('healthinsurances/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const healthinsurancesSlice = createSlice({
  name: 'healthinsurances',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    },
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getHealthInsurancesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getHealthInsurance.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getHealthInsurancesInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addHealthInsurance.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
        state.checkCode         = null
        state.resendCode        = null
      })
      .addCase(updateHealthInsurance.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(deleteHealthInsurance.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.checkCode = null
        state.resendCode = null
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
      })
  }
})

export const { handleRemove, removePostCodeSearch } = healthinsurancesSlice.actions

export default healthinsurancesSlice.reducer