// ** Icons Import
import { Home, Users, Award, CheckCircle, List, Star, DollarSign, Box, Bookmark, FileText, Edit2, Heart, CheckSquare, Tag, Truck, Target, Book, Monitor } from 'react-feather'

export default [
  {
    id: 'painel',
    title: 'Dashboard',
    icon: <Monitor size={20} />,
    navLink: '/painel',
    role: ['system', 'provider', 'hospital', 'healthinsurance'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral']
  },
  {
    id: 'financeiro',
    title: 'Financial',
    icon: <DollarSign size={20} />,
    navLink: '/financeiro',
    role: ['system', 'provider', 'hospital', 'healthinsurance'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral', 'Financial']
  },
  {
    id: 'usuarios',
    title: 'Users',
    icon: <Users size={20} />,
    navLink: '/usuarios',
    role: ['system', 'provider', 'hospital', 'healthinsurance'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral']
  },
  {
    id: 'empresas',
    title: 'Companies',
    icon: <Award size={20} />,
    role: ['provider', 'hospital', 'healthinsurance'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral'],
    navLink: '/empresas'
  },
  {
    id: 'empresas',
    title: 'Companies',
    icon: <Award size={20} />,
    role: ['system'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral'],
    children: [
      {
        id: 'empresas-listar',
        title: 'List',
        icon: <List size={20} />,
        navLink: '/empresas/listar',
        role: ['system'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral']
      },
      {
        id: 'empresas-documentos',
        title: 'Documents',
        icon: <CheckCircle size={20} />,
        navLink: '/empresas/documentos',
        role: ['system'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral']
      }
    ]
  },
  {
    id: 'produtos',
    title: 'Products',
    icon: <Box size={20} />,
    role: ['system', 'provider'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes', 'ManagerProducts'],
    children: [
      {
        id: 'produtos-listar',
        title: 'List',
        icon: <List size={20} />,
        navLink: '/produtos',
        role: ['system', 'provider'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes', 'ManagerProducts']
      },
      {
        id: 'produtos-categorias',
        title: 'Categories',
        icon: <Tag size={20} />,
        navLink: '/categorias',
        role: ['system', 'provider'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes', 'ManagerProducts']
      },
      {
        id: 'produtos-fabricantes',
        title: 'Manufacturers',
        icon: <Truck size={20} />,
        navLink: '/fabricantes',
        role: ['system', 'provider'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes', 'ManagerProducts']
      }
    ]
  },
  {
    id: 'quotes',
    title: 'Quotations',
    icon: <Target size={20} />,
    navLink: '/cotacoes',
    role: ['system', 'hospital', 'healthinsurance', 'provider'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
  },
  {
    id: 'propostas',
    title: 'Proposals',
    icon: <Bookmark size={20} />,
    navLink: '/propostas',
    role: ['system', 'hospital', 'healthinsurance', 'provider'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
  },
  {
    id: 'contratos',
    title: 'Contracts',
    icon: <FileText size={20} />,
    role: ['system'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral'],
    children: [
      {
        id: 'contratos-listar',
        title: 'List',
        icon: <List size={20} />,
        navLink: '/contratos',
        role: ['system'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral']
      },
      {
        id: 'empresas-faturamento',
        title: 'Billing Companies',
        icon: <Home size={20} />,
        navLink: '/empresas-faturamento',
        role: ['system'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral']
      }
    ]
  },
  {
    id: 'gerencia',
    title: 'Manage',
    icon: <Book size={20} />,
    role: ['system', 'hospital', 'healthinsurance'],
    action: 'read',
    resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes'],
    children: [
      {
        id: 'gerencia-clientes',
        title: 'Customers',
        icon: <Star size={20} />,
        navLink: '/clientes',
        role: ['system', 'hospital', 'healthinsurance'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
      },
      {
        id: 'gerencia-procedimentos',
        title: 'Procedures',
        icon: <Edit2 size={20} />,
        navLink: '/procedimentos',
        role: ['system', 'hospital', 'healthinsurance'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
      },
      {
        id: 'gerencia-hospitais',
        title: 'Hospitals',
        icon: <Heart size={20} />,
        navLink: '/hospitais',
        role: ['system', 'healthinsurance', 'hospital'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
      },
      {
        id: 'gerencia-convenios',
        title: 'Health Insurances',
        icon: <CheckSquare size={20} />,
        navLink: '/convenios',
        role: ['system', 'hospital', 'healthinsurance'],
        action: 'read',
        resource: ['Admin', 'ManagerGeneral', 'ManagerQuotes']
      }
    ]
  }
]