// ** Third Party Components
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

import { useLanguage } from '@hooks/useLanguage'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

const IntlDropdown = () => {
  // ** Hooks
  const { t } = useTranslation()
  const { language, setLanguage, setTag } = useLanguage()

  // ** Vars
  const langObj = {
    pt: 'Portuguese',
    br: 'Portuguese',
    en: 'English',
    es: 'Spanish'
  }

  const langFlag = {
    pt: 'br',
    br: 'br',
    en: 'us',
    es: 'es'
  }

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    setLanguage(lang)
    setTag(lang)
  }

  return (
    <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
        <ReactCountryFlag
          svg
          className='country-flag flag-icon'
          countryCode={langFlag[language ? language : 'pt']}
        />
        <span className='selected-language'>{t(langObj[language ? language : 'pt'])}</span>
      </DropdownToggle>      
      <DropdownMenu className='mt-0' end>
        <DropdownItem href='/' tag='a' className={!language || language === 'pt' ? 'active' : ''} onClick={e => handleLangUpdate(e, 'pt')}>
          <ReactCountryFlag className='country-flag' countryCode='br' svg />
          <span className='ms-1'>{t('Portuguese')}</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' className={language === 'en' ? 'active' : ''} onClick={e => handleLangUpdate(e, 'en')}>
          <ReactCountryFlag className='country-flag' countryCode='us' svg />
          <span className='ms-1'>{t('English')}</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' className={language === 'es' ? 'active' : ''} onClick={e => handleLangUpdate(e, 'es')}>
          <ReactCountryFlag className='country-flag' countryCode='es' svg />
          <span className='ms-1'>{t('Spanish')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
