// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense } from 'react'

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const ability = useContext(AbilityContext)
  const user = JSON.parse(localStorage.getItem('userData'))

  const checkResources = (action, resource) => {
    let resp = false
    if (typeof resource !== 'string') {
      for (const key in resource) {
        if (ability.can(action || 'read', resource[key])) {
          resp = true
          break
        }
      }
    } else {
      return ability.can(action || 'read', resource)
    }

    return resp
  }

  if (route) {
    let action = null
    let resource = null
    let restrictedRoute = false
    let role = []

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
      role = route.meta.role ? route.meta.role : []
    }
    if (!user || (user && !user.role)) {
      return <Navigate to='/login' />
    }
    if (user && restrictedRoute) {
      return <Navigate to='/' />
    }
    if (user && restrictedRoute && user.role === 'client') {
      return <Navigate to='/access-control' />
    }
    if ((user && checkResources(action, resource) === false) || !role.includes(user.role)) {
      return <Navigate to='/acesso-negado' replace />
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
