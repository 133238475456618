// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Statistics Users
export const getStatisticsUsers = createAsyncThunk('statistics/getStatisticsUsers', async (params) => {
  const response = await api
  .get(`statistic/users`, { params })
  .catch(err => console.log(err))

  return response.data.data
})

export const getStatisticsOrders = createAsyncThunk('statistics/getStatisticsOrders', async (params) => {
  const response = await api
  .get(`statistic/orders`, { params })
  .catch(err => console.log(err))

  return response.data.data
})

export const getStatisticsProposals = createAsyncThunk('statistics/getStatisticsProposals', async (params) => {
  const response = await api
  .get(`statistic/proposals`, { params })
  .catch(err => console.log(err))

  return response.data.data
})

export const getStatisticsQuotes = createAsyncThunk('statistics/getStatisticsQuotes', async (params) => {
  const response = await api
  .get(`statistic/quotes`, { params })
  .catch(err => console.log(err))

  return response.data.data
})

export const getStatisticsProducts = createAsyncThunk('statistics/getStatisticsProducts', async (params) => {
  const response = await api
  .get(`statistic/products`, { params })
  .catch(err => console.log(err))

  return response.data.data
})

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    users: null,
    orders: null,
    ordersAmount: null,
    proposals: null,
    quotes: null,
    products: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload   = action.payload
      state.users     = payload.users ? state.users : null
      state.orders    = payload.orders ? state.orders : null
      state.proposals = payload.proposals ? state.proposals : null
      state.quotes    = payload.quotes ? state.quotes : null
      state.products  = payload.products ? state.products : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getStatisticsUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(getStatisticsOrders.fulfilled, (state, action) => {
        state.orders        = action.payload
        state.ordersAmount  = action.payload
      })
      .addCase(getStatisticsProposals.fulfilled, (state, action) => {
        state.proposals = action.payload
      })
      .addCase(getStatisticsQuotes.fulfilled, (state, action) => {
        state.quotes = action.payload
      })
      .addCase(getStatisticsProducts.fulfilled, (state, action) => {
        state.products = action.payload
      })
  }
})

export const { handleRemove } = statisticsSlice.actions

export default statisticsSlice.reducer