// ** Icons Import
import { Headphones, Settings } from 'react-feather'

export default [
  {
    header: 'Settings',    
    role: ['system'],
    action: 'read',
    resource: 'Admin'
  },
  {
    id: 'sistema',
    title: 'System',
    icon: <Settings size={20} />,
    navLink: '/configuracoes/sistema',
    role: ['system'],
    action: 'read',
    resource: 'Admin'
  },
  {
    header: 'Support',    
    role: ['provider', 'healthinsurance', 'hospital'],
    action: 'read',
    resource: 'Auth'
  },
  {
    id: 'suporte-cliente',
    title: 'Support',
    icon: <Headphones size={12} />,
    role: ['provider', 'healthinsurance', 'hospital'],
    action: 'read',
    resource: 'Auth',
    externalLink: true,
    newTab: true,
    navLink: 'https://api.whatsapp.com/send?phone=5511996922275&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%20Innercom.%20Pode%20me%20ajudar%3F'
  }
]