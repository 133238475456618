// ** React Imports
import { useEffect, useState } from 'react'

// ** Store Imports
import { handleLogin } from '@store/authentication'
import { updateSelectCompany } from '@store/select_companies'
import { useDispatch, useSelector } from 'react-redux'

import { getSelectCompanyId, getSelectCompany, formatUpdateUser } from '@utils'

export const useCompany = () => {
  // ** Hooks
  const dispatch          = useDispatch(),
        select_companies  = useSelector(state => state.select_companies)

  // ** States
  const [selectCompany, setSelectCompany] = useState(select_companies.selected)

  const setCompany = (idC = null) => {
    const data = idC ? { id: idC } : (getSelectCompanyId() ? { id: getSelectCompanyId() } : {})
    dispatch(updateSelectCompany(data))
  }

  useEffect(() => {
    if (select_companies && select_companies.data && select_companies.data.length) {
      dispatch(handleLogin(formatUpdateUser(select_companies.data, 'companies')))
      if (getSelectCompanyId()) setSelectCompany(getSelectCompany())
    } else {
      setCompany()
    }
  }, [select_companies.data])

  return { company: selectCompany, companies: select_companies.data, setCompany }
}