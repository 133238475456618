// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Quotes Paginate
export const getQuotesPaginate = createAsyncThunk('quotes/getQuotesPaginate', async (params) => {
  const response = await api
  .get(`quotes/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Quote
export const getQuote = createAsyncThunk('quotes/getQuote', async (id) => {
  const response = await api
  .get(`quote/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getQuotesInputList = createAsyncThunk('quotes/getQuotesInputList', async (params) => {
  const response = await api
  .get(`quotes/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Quote
export const addQuote = createAsyncThunk('quotes/addQuote', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('quote', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Quote
export const updateQuote = createAsyncThunk('quotes/updateQuote', async (data) => {
  const response = await api
  .put(`quote/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Surgery Date
export const updateSurgeryDateQuote = createAsyncThunk('quotes/updateSurgeryDateQuote', async (data) => {
  const response = await api
  .put(`quote/surgery/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Quote
export const deleteQuote = createAsyncThunk('quotes/deleteQuote', async (id) => {
  const response = await api
  .delete(`quote/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Send Invoicing
export const sendInvoincingQuote = createAsyncThunk('quotes/sendInvoincingQuote', async (data) => {
  const response = await api
  .put(`quote/invoicing/${data.id}`, data.data)
  .catch(err => console.log(err))

  return response.data
})

export const requestPostSurgeryQuote = createAsyncThunk('quotes/requestPostSurgeryQuote', async (id) => {
  const response = await api
  .put(`quote/postsurgery/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const updateDueDateQuote = createAsyncThunk('quotes/updateDueDateQuote', async (data) => {
  const response = await api
  .put(`quote/duedate/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null,
    surgery: null,
    invoicing: null,
    postsurgery: null,
    duedate: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
      state.surgery   = payload.surgery ? state.surgery : null
      state.invoicing = payload.invoicing ? state.invoicing : null
      state.postsurgery = payload.postsurgery ? state.postsurgery : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getQuotesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.invoicing   = null
        state.postsurgery = null
        state.surgery     = null
      })
      .addCase(getQuote.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getQuotesInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      })
      .addCase(addQuote.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
      })
      .addCase(updateQuote.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(updateSurgeryDateQuote.fulfilled, (state, action) => {
        state.surgery   = action.payload
      })
      .addCase(deleteQuote.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
      .addCase(sendInvoincingQuote.fulfilled, (state, action) => {
        state.invoicing   = action.payload
      })
      .addCase(requestPostSurgeryQuote.fulfilled, (state, action) => {
        state.postsurgery   = action.payload
      })
      .addCase(updateDueDateQuote.fulfilled, (state, action) => {
        state.duedate = action.payload
      })
  }
})

export const { handleRemove } = quotesSlice.actions

export default quotesSlice.reducer