// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Files Paginate
export const getFilesPaginate = createAsyncThunk('files/getFilesPaginate', async (params) => {
  const response = await api
  .get(`files/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Files
export const getFiles = createAsyncThunk('files/getFiles', async (params) => {
  const response = await api
  .get(`files`, { params })
  .catch(err => console.log(err))

  return response.data.data
})

// ** PUT Updates Files Check
export const updateFilesCheck = createAsyncThunk('files/updateFilesCheck', async (id) => {
  const response = await api
  .put(`file/check/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete File
export const deleteFile = createAsyncThunk('files/deleteFile', async (id) => {
  const response = await api
  .delete(`file/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const failFile = createAsyncThunk('files/failFile', async (id) => {
  const response = await api
  .post(`file/fail/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const filesSlice = createSlice({
  name: 'files',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    check: null,
    fail: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.check = payload.check ? state.check : null
      state.fail  = payload.fail ? state.fail : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getFilesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.check     = null
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        state.data      = action.payload
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.check     = null
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
        state.check     = null
      })
      .addCase(failFile.fulfilled, (state, action) => {
        state.fail  = action.payload
        state.check = null
      })
      .addCase(updateFilesCheck.fulfilled, (state, action) => {
        state.check = action.payload
      })
  }
})

export const { handleRemove } = filesSlice.actions

export default filesSlice.reducer