// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

const initialLanguage = () => {
  const item = window.localStorage.getItem('language')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.language
}

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language: initialLanguage()
  },
  reducers: {
    handleLanguage: (state, action) => {
      state.language = action.payload
      window.localStorage.setItem('language', JSON.stringify(action.payload))
    }
  }
})

export const { handleLanguage } = languageSlice.actions

export default languageSlice.reducer
