// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import language from './language'
import avatar from './avatar'
import select_companies from './select_companies'

import users from './users'
import users_addresses from './users/addresses'
import users_contacts from './users/contacts'

import companies from './companies'
import companies_addresses from './companies/addresses'

import files from './files'

import quotes from './quotes'
import customers from './customers'
import procedures from './procedures'
import hospitals from './hospitals'
import healthinsurances from './healthinsurances'

import proposals from './proposals'

import products from './products'
import categories from './categories'
import manufacturers from './manufacturers'

import contracts from './contracts'
import billingcompanies from './billingcompanies'

import orders from './orders'

import settings from './settings'

import statistics from './statistics'

const rootReducer = {
  auth,
  navbar,
  layout,
  language,
  avatar,
  select_companies,

  users,
  users_addresses,
  users_contacts,

  products,
  categories,
  manufacturers,

  companies,
  companies_addresses,

  files,

  quotes,
  customers,
  procedures,
  hospitals,
  healthinsurances,
  
  proposals,

  contracts,
  billingcompanies,

  orders,

  settings,

  statistics
}

export default rootReducer
