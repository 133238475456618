// ** React Imports
import { lazy } from 'react'

const Error         = lazy(() => import('../../views/general/Error'))
const NotAuthorized = lazy(() => import('../../views/general/NotAuthorized'))

const General = [
  {
    path: '/acesso-restrito',
    element: <NotAuthorized />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/erro',
    element: <Error />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }
]

export default General