// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getSelectCompany } from '@utils'

const initialDataCompany = () => {
  const item = JSON.parse(window.localStorage.getItem('userData'))

  if (item && item.companies && item.companies.length) {
    return item.companies 
  } else {
    return []
  }
}

export const updateSelectCompany = createAsyncThunk('select_companies/updateSelectCompany', async (data) => {
  const response = await api
  .post(`user/select-company`, data)
  .catch(err => console.log(err))

  return response.data
})

export const selectCompaniesSlice = createSlice({
  name: 'select_companies',
  initialState: {
    data: initialDataCompany(),
    selected: getSelectCompany()
  },
  reducers: {
    handleCompany: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(updateSelectCompany.fulfilled, (state, action) => {
        state.data      = action.payload.data
        state.selected  = action.payload.selected
      })
  }
})

export const { handleCompany } = selectCompaniesSlice.actions

export default selectCompaniesSlice.reducer
