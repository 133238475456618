// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Customers Paginate
export const getCustomersPaginate = createAsyncThunk('customers/getCustomersPaginate', async (params) => {
  const response = await api
  .get(`customers/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Customer
export const getCustomer = createAsyncThunk('customers/getCustomer', async (id) => {
  const response = await api
  .get(`customer/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getCustomersInputList = createAsyncThunk('customers/getCustomersInputList', async (params) => {
  const response = await api
  .get(`customers/input-list`, { params })
  .catch(err => console.log(err))

  response.data.params = params

  return response.data
})

//** Add Customer
export const addCustomer = createAsyncThunk('customers/addCustomer', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('customer', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Customer
export const updateCustomer = createAsyncThunk('customers/updateCustomer', async (data) => {
  const response = await api
  .put(`customer/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Customer
export const updateStatusCustomer = createAsyncThunk('customers/updateStatusCustomer', async (id) => {
  const response = await api
  .put(`customer/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Customer
export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (id) => {
  const response = await api
  .delete(`customer/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const searchPostcode = createAsyncThunk('customers/searchPostcode', async (val) => {
  const response = await api
  .get(`postcode/${val.replace('-', '')}`)
  .catch(err => console.log(err))

  return response.data
})

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null,
    inputListDoctors: null,
    selectedSearch: null,
    selectedPostCode: null,
    selectedPostCodeStatus: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    },
    removePostCodeSearch: (state, action) => {
      const payload = action.payload
      state.selectedSearch = payload.selectedSearch ? state.selectedSearch : null
      state.selectedPostCode = payload.selectedPostCode ? state.selectedPostCode : null
      state.selectedPostCodeStatus = payload.selectedPostCodeStatus ? state.selectedPostCodeStatus : null
      state.added = payload.added ? null : state.added
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCustomersPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getCustomersInputList.fulfilled, (state, action) => {
        if (action.payload.params.access === 'doctor') state.inputListDoctors = action.payload.data
        else state.inputList = action.payload.data
      }) 
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
      .addCase(searchPostcode.fulfilled, (state, action) => {
        state.selectedPostCode          = action.payload.data
        state.selectedPostCodeStatus    = action.payload.success
      })
  }
})

export const { removePostCodeSearch, handleRemove } = customersSlice.actions

export default customersSlice.reducer