// ** React Imports
import { lazy } from 'react'

const Login           = lazy(() => import('../../views/auth/Login'))
const Register        = lazy(() => import('../../views/auth/Register'))
const ForgotPassword  = lazy(() => import('../../views/auth/ForgotPassword'))
const VerifyEmai      = lazy(() => import('../../views/auth/VerifyEmail'))
const ChangeUser      = lazy(() => import('../../views/auth/ChangeUser'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/cadastrar',
    element: <Register />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/recuperar-senha',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/verificar-conta',
    element: <VerifyEmai />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/trocar-usuario',
    element: <ChangeUser />,
    meta: {
        layout: 'blank',
        publicRoute: true
    }
  }
]

export default AuthenticationRoutes
