// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Categories Paginate
export const getCategoriesPaginate = createAsyncThunk('categories/getCategoriesPaginate', async (params) => {
  const response = await api
  .get(`categories/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Category
export const getCategory = createAsyncThunk('categories/getCategory', async (id) => {
  const response = await api
  .get(`category/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getCategoriesInputList = createAsyncThunk('categories/getCategoriesInputList', async (params) => {
  const response = await api
  .get(`categories/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Category
export const addCategory = createAsyncThunk('categories/addCategory', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('category', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Category
export const updateCategory = createAsyncThunk('categories/updateCategory', async (data) => {
  const response = await api
  .put(`category/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Category
export const updateStatusCategory = createAsyncThunk('categories/updateStatusCategory', async (id) => {
  const response = await api
  .put(`category/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Category
export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (id) => {
  const response = await api
  .delete(`category/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCategoriesPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.data      = []
        state.total     = null
      })
      .addCase(getCategoriesInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data ? action.payload.data : []
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      }) 
      .addCase(addCategory.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
        state.selectedSearch    = null
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
  }
})

export const { handleRemove } = categoriesSlice.actions

export default categoriesSlice.reducer