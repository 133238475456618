// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Procedures Paginate
export const getProceduresPaginate = createAsyncThunk('procedures/getProceduresPaginate', async (params) => {
  const response = await api
  .get(`procedures/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Procedure
export const getProcedure = createAsyncThunk('procedures/getProcedure', async (id) => {
  const response = await api
  .get(`procedure/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getProceduresInputList = createAsyncThunk('procedures/getProceduresInputList', async (params) => {
  const response = await api
  .get(`procedures/input-list`, { params })
  .catch(err => console.log(err))

  return response.data
})

//** Add Procedure
export const addProcedure = createAsyncThunk('procedures/addProcedure', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('procedure', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Procedure
export const updateProcedure = createAsyncThunk('procedures/updateProcedure', async (data) => {
  const response = await api
  .put(`procedure/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking Procedure
export const updateStatusProcedure = createAsyncThunk('procedures/updateStatusProcedure', async (id) => {
  const response = await api
  .put(`procedure/status/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Procedure
export const deleteProcedure = createAsyncThunk('procedures/deleteProcedure', async (id) => {
  const response = await api
  .delete(`procedure/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const proceduresSlice = createSlice({
  name: 'procedures',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    inputList: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProceduresPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getProcedure.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.deleted   = null
      })
      .addCase(getProceduresInputList.fulfilled, (state, action) => {
        state.inputList = action.payload.data
      }) 
      .addCase(addProcedure.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
      })
      .addCase(updateProcedure.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteProcedure.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
  }
})

export const { handleRemove } = proceduresSlice.actions

export default proceduresSlice.reducer