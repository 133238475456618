// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_API_URL}/login`,
  registerEndpoint: `${process.env.REACT_APP_API_URL}/user`,
  refreshEndpoint: `${process.env.REACT_APP_API_URL}/refresh`,
  logoutEndpoint: `${process.env.REACT_APP_API_URL}/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageTokenKeyAdminName: 'accessTokenAdmin',
  storageRefreshTokenKeyName: 'refreshToken'
}
