// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Products Paginate
export const getProductsPaginate = createAsyncThunk('products/getProductsPaginate', async (params) => {
  const response = await api
  .get(`products/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Product
export const getProduct = createAsyncThunk('products/getProduct', async (id) => {
  const response = await api
  .get(`product/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getFilesProducts = createAsyncThunk('products/getFilesProducts', async (data) => {
  const response = await api
  .get(`files/${data.id}?type=${data.type}`, { data })
  .catch(err => console.log(err))

  return response.data
})

//** Add Product
export const addProduct = createAsyncThunk('products/addProduct', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('product', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Product
export const updateProduct = createAsyncThunk('products/updateProduct', async (data) => {
  const response = await api
  .put(`product/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Checking
export const updateCheckingProduct = createAsyncThunk('products/updateCheckingProduct', async (id) => {
  const response = await api
  .put(`product/checking/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Product
export const deleteProduct = createAsyncThunk('products/deleteProduct', async (id) => {
  const response = await api
  .delete(`product/${id}`)
  .catch(err => console.log(err))

  return response.data
})

export const deleteMedia = createAsyncThunk('products/deleteMedia', async (params) => { 
  const response = await api
  .delete(`file/${params.idM}?id=${params.prodId}`)
  .catch(err => console.log(err))

  //if (response.data.success) { params.el.remove(); params.el.removeChild(params.el) }

  return response.data
})

export const selectMediaCover = createAsyncThunk('products/selectMediaCover', async (params) => { 
  const response = await api
  .post(`file/select-cover/${params.idM}`, { type: 'product' })
  .catch(err => console.log(err))

  if (params.elp) {
      const classN = params.elp.getElementsByClassName("select-cover")
      for (let i = 0; i < classN.length; i++) {
          classN[i].classList.remove("btn-warning")
          classN[i].classList.add("btn-dark")
      }
  }

  if (response.data.success && params.el) { 
      params.el.classList.add("btn-warning")
      params.el.classList.remove("btn-dark") 
  }

  return response.data
})

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    total: null,
    params: {},
    files: null,
    filesSuccess: false,
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    deletedMedia: null,
    selectMediaCover: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProductsPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getFilesProducts.fulfilled, (state, action) => {
        state.files         = action.payload.data
        state.filesSuccess  = action.payload.success
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = action.payload.data
        state.updated           = null
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
      .addCase(deleteMedia.fulfilled, (state, action) => {
        state.deletedMedia = action.payload
      })
      .addCase(selectMediaCover.fulfilled, (state, action) => {
        state.selectMediaCover = action.payload
      })
  }
})

export const { handleRemove } = productsSlice.actions

export default productsSlice.reducer