// ** React Imports
import { useEffect, useState } from 'react'

import { getUrlDocsImgsTypes } from '@utils'

// ** Store Imports
import { handleAvatar } from '@store/avatar'
import { useDispatch, useSelector } from 'react-redux'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'

export const useAvatar = () => {
  // ** Hooks
  const dispatch  = useDispatch(),
        avatar    = useSelector(state => state.avatar)

  // ** States
  const [avatarSelect, setAvatarSelect] = useState(defaultAvatar)

  const selAvatar = () => (<Avatar img={avatar.avatar ? getUrlDocsImgsTypes(avatarSelect, 2) : defaultAvatar} imgHeight='40' imgWidth='40' status='online' />)

  const setAvatar = avatar => dispatch(handleAvatar(avatar))

  useEffect(() => {
    if (avatar && avatar.avatar && avatarSelect !== avatar.avatar) setAvatarSelect(avatar.avatar)
  }, [avatar.avatar])

  return { avatar: selAvatar(), setAvatar, avatarSelect }
}
