// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API Imports
import api from '@src/api'

// ** GET Proposals Paginate
export const getProposalsPaginate = createAsyncThunk('proposals/getProposalsPaginate', async (params) => {
  const response = await api
  .get(`proposals/paginate`, { params })
  .catch(err => console.log(err))

  return response.data
})

// ** GET Proposal
export const getProposal = createAsyncThunk('proposals/getProposal', async (id) => {
  const response = await api
  .get(`proposal/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

export const getProposalQuoteId = createAsyncThunk('proposals/getProposalQuoteId', async (id) => {
  const response = await api
  .get(`proposal/quote/${id}`)
  .catch(err => console.log(err))

  return response.data.data
})

//** Add Proposal
export const addProposal = createAsyncThunk('proposals/addProposal', async (data) => { // , { dispatch, getState }
  const response = await api
  .put('proposal', data)
  .catch(err => console.log(err))

  return response.data
})

// ** Update Proposal
export const updateProposal = createAsyncThunk('proposals/updateProposal', async (data) => {
  const response = await api
  .put(`proposal/${data.id}`, data)
  .catch(err => console.log(err))

  return response.data
})

// ** Delete Proposal
export const deleteProposal = createAsyncThunk('proposals/deleteProposal', async (id) => {
  const response = await api
  .delete(`proposal/${id}`)
  .catch(err => console.log(err))

  return response.data
})

// ** Accept Proposal
export const acceptProposal = createAsyncThunk('proposals/acceptProposal', async (data) => {
  const response = await api
  .post(`proposal/accept/${data._id}`, data)
  .catch(err => console.log(err))

  return response.data
})

export const proposalsSlice = createSlice({
  name: 'proposals',
  initialState: {
    data: [],
    total: null,
    params: {},
    selected: null,
    updated: null,
    added: null,
    deleted: null,
    accept: null
  },
  reducers: {
    handleRemove: (state, action) => {
      const payload = action.payload
      state.selected  = payload.selected ? state.selected : null
      state.added     = payload.added ? state.added : null
      state.updated   = payload.updated ? state.updated : null
      state.deleted   = payload.deleted ? state.deleted : null
      state.accept    = payload.accept ? state.accept : null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProposalsPaginate.fulfilled, (state, action) => {
        state.data      = action.payload.data && action.payload.data.docs ? action.payload.data.docs : []
        state.total     = action.payload.data && action.payload.data.totalDocs ? action.payload.data.totalDocs : 0
        state.selected  = null
        state.added     = null
        state.updated   = null
        state.deleted   = null
        state.accept    = null
      })
      .addCase(getProposal.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(getProposalQuoteId.fulfilled, (state, action) => {
        state.selected  = action.payload
        state.added     = null
        state.updated   = null
        state.deleted   = null
      })
      .addCase(addProposal.fulfilled, (state, action) => {
        state.added             = action.payload
        state.selected          = null
        state.updated           = null
      })
      .addCase(updateProposal.fulfilled, (state, action) => {
        state.updated   = action.payload
        state.added     = null
      })
      .addCase(deleteProposal.fulfilled, (state, action) => {
        state.selected  = null
        state.deleted   = action.payload
      })
      .addCase(acceptProposal.fulfilled, (state, action) => {
        state.accept   = action.payload
      })
  }
})

export const { handleRemove } = proposalsSlice.actions

export default proposalsSlice.reducer